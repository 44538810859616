import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";

import { CheckedRounedOutlineIcon, ClockIcon, TimerIcon } from "../../assets/images/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { updateStatusByOrderId } from "../../redux/services/orders";

interface StatusSwitchProps {
  status: string;
  orderId: number;
  onDispatch?: () => void;
  activeTab?: string;
  disabled?: boolean; // Optional prop with default value
  onCancel?: () => void;
  orderDetails?: boolean
}

const StatusSwitch = ({ status, orderId, onDispatch, activeTab, disabled = false, orderDetails = false, onCancel }: StatusSwitchProps) => {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleScroll = (event: Event) => {
      setOpen(false);
    };
    const element = document.querySelector(".ant-table-body");
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const changeOrderStatus = (nextStatus: string) => {
    setOpen(false);
    if (nextStatus === "DISPATCHED") {
      onDispatch && onDispatch();
    }
    else if (nextStatus === "CANCELLED") {
      setOpen(false);
      onCancel && onCancel();
    }
    else {
      dispatch(updateStatusByOrderId({ orderId: orderId, action: nextStatus })).finally(() => setOpen(false));
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = () => (
    <div className="status-popover-content">
      {(status === "Accepted") && (activeTab === "Customer-order") &&
        <div className="status-box in-progress">
          <button onClick={() => changeOrderStatus("IN_PROGRESS")}><ClockIcon /> In Process</button>
        </div>}

      {(status === "inProgress" || status === "Partially Dispatched") && (activeTab === "Customer-order") &&
        <>
          {/* <div className="status-box partially-dispatch">
            <button onClick={() => changeOrderStatus("Partially Dispatched")}><CheckedRounedOutlineIcon /> Partially Dispatched</button>
          </div> */}
          <div className="status-box dispatch">
            <button onClick={() => changeOrderStatus("DISPATCHED")}><CheckedRounedOutlineIcon /> Dispatched</button>
          </div>
        </>}

      {/* {status === "Partially Dispatched" && (activeTab === "My-order") &&
        <div className="status-box partially-received">
          <button onClick={() => changeOrderStatus("Partially Received")}><CheckedRounedOutlineIcon /> Partially Received</button>
        </div>} */}
      {(status === "Dispatched" || status === "Partially Dispatched") && (activeTab === "My-order") &&
        <div className="status-box received">
          <button onClick={() => changeOrderStatus("COMPLETED")}><CheckedRounedOutlineIcon /> Received</button>
        </div>}

      {(status === "Pending" || status === "Accepted" || status === "inProgress") &&
        <div className="status-box cancel-order">
          <button onClick={() => changeOrderStatus("CANCELLED")}><InfoCircleOutlined /> Cancel Order</button>
        </div>}
    </div>
  );

  return (
    <div className="status-switch-wrapper">
      {status === "Accepted" ?
        <div className={activeTab === "Customer-order" ? "switch accepted" : "switch accepted outlined"}>
          <Popover open={open} onOpenChange={handleOpenChange} content={content} trigger={!disabled && activeTab === "Customer-order" ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className={activeTab === "Customer-order" ? "accepted" : "accepted outlined"}>Accepted</button>
          </Popover>
          {activeTab === "Customer-order" ? <button className="arrow-down-button" onClick={() => activeTab === "Customer-order" ? changeOrderStatus("IN_PROGRESS") : []}>
            {activeTab === "Customer-order" ? <RightOutlined /> : <div className="my-order-accepted"><CheckedRounedOutlineIcon /></div>}
          </button> : <></>}
        </div> : <></>}

      {(status === "In Progress" || status === "inProgress") ?
        (activeTab === "Customer-order" ? <div className="switch inProgress">
          <Popover open={open} onOpenChange={handleOpenChange} content={content} trigger={!disabled && activeTab === "Customer-order" ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className={activeTab === "Customer-order" ? "inProgress" : "inProgress outlined"}>In Process</button>
          </Popover>
          <button className="arrow-down-button" onClick={() => !disabled && activeTab === "Customer-order" ? changeOrderStatus("DISPATCHED") : []}><TimerIcon /></button>
        </div> : <div className={`switch in-process-myorder ${orderDetails && "order-inprocess-switch"}`}>In - process</div>) : <></>}

      {status === "Dispatched" ?
        <div className={`switch dispatched ${orderDetails && "order-detail-dispatched"}`}>
          <Popover open={open} onOpenChange={handleOpenChange} content={content} trigger={!disabled && activeTab === "My-order" ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className="outlined">Dispatched</button>
          </Popover>
          {/* <button className="arrow-down-button"><CheckedRounedOutlineIcon /></button> */}
        </div> : <></>}

      {status === "Rejected" ?
        <div className={`switch rejected ${orderDetails && "order-detail-cancelled"}`}>
          <button className="rejected outlined">Rejected</button>
          {/* <button className="arrow-down-button"><InfoCircleOutlined /></button> */}
        </div> : <></>}
      {status === "Completed" ?
        <div className={activeTab === "Customer-order" ? "switch completed outlined" : "switch completed"}>
          <button className={activeTab === "Customer-order" ? "completed" : "completed"}>{activeTab === "Customer-order" ? "Delivered" : "Received"}</button>
          {/* <button className="arrow-down-button"><CheckedRounedOutlineIcon /></button> */}
        </div> : <></>}

      {status === "Pending" ?
        <div className="switch pending">
          <Popover open={open} onOpenChange={handleOpenChange} content={content} trigger={!disabled ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className="pending">Pending</button>
          </Popover>
          <button className="arrow-down-button"><ClockIcon /></button>
        </div> : <></>}

      {status === "Partially Dispatched" ?
        <div className="switch partially-dispatched">
          <Popover open={open} onOpenChange={handleOpenChange} content={content} trigger={!disabled ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className="pending">Partially Dispatched</button>
          </Popover>
          <button className="arrow-down-button" onClick={() => !disabled && activeTab === "Customer-order" ? changeOrderStatus("DISPATCHED") : []}><RightOutlined /></button>
        </div>
        : <></>}

      {status === "Cancelled" ?
        <div className={`switch canceled ${orderDetails && "order-detail-cancelled"}`}>
          <button className="canceled outlined">Cancelled</button>
          {/* <button className="arrow-down-button"><InfoCircleOutlined /></button> */}
        </div> : <></>}
    </div>
  );
};

export default StatusSwitch;