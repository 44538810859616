import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoint from "./endPoints.json";
import axiosInstance from "../axios";
import { BulkRateEntry } from "../../types/productsType";

export const getCustomersList = createAsyncThunk("specialPriceList/getCustomersList", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoint.specialPriceListGetCustomerList);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getCompanySpecialPriceDetailsById = createAsyncThunk("specialPriceList/getCustomerDetailsById", async (args: { id: number, limit: number, offset: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${endPoint.specialPriceListGetCustomerDetails}/${args.id}`, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getProductsList = createAsyncThunk("specialPriceList/getProductsList", async (args: {
  search: string,
  customerId: number,
  limit: number,
  offset: number
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoint.specialPriceProductList, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getCustomerProductSizes = createAsyncThunk("specialPriceList/specialPriceProductSizes", async (args: { productId: number, customerId: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoint.specialPriceProductSizes}/${args.productId}/${args.customerId}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const addProductsForCustomer = createAsyncThunk("specialPriceList/addProductsForCustomer", async (payload: { customerId: number, selectedProducts: { productId: number, productSizeIds: number[] }[] }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoint.addSpecialPrice, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const deleteSpecialPrice = createAsyncThunk("specialPriceList/deleteSpecialPrice", async (payload: { ids: number[] }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoint.deleteSpecialPrice, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateSpecialPriceBulkRate = createAsyncThunk("specialProductCustomization/updateSpecialBulkrate", async (args: { id: number, payload: BulkRateEntry[] | [] }, { rejectWithValue }) => {
  const { id, payload } = args;
  try {
    const response = await axiosInstance.patch(`${endPoint.specialPriceUpdateBulkRate}/${id}`, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateSpecialPriceList = createAsyncThunk("specialPriceList/updatePriceList", async (args: {
  id: number, payload: {
    specialPricePercentage: number | null,
    specialPriceRupees: number | null,
    alternateUnitQuantity: number,
    alternateUnitPrimaryUnit: string,
    alternateUnitSecondaryUnit: string,
    moq: number | null,
  }
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(`${endPoint.updateSpecialPrice}/${args.id}`, args.payload);
    if (response.status === 200) {
      message.success(response?.data?.message);
    }
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});
