import React from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDDH7dglQuwH8RYfLqr9d3x0b1rTHWUg80",
  authDomain: "biizline-c34a2.firebaseapp.com",
  projectId: "biizline-c34a2",
  storageBucket: "biizline-c34a2.firebasestorage.app",
  messagingSenderId: "500391746510",
  appId: "1:500391746510:web:9985c1c063b5969375a99f",
  measurementId: "G-JH9ZRZWBZX",
};

export async function requestPermission() {
  try {
    // Check if Notification API is supported
    if (typeof Notification !== "undefined") {
      // Request permission for notifications
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        // Initialize Firebase app
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);

        // Register service worker
        await navigator.serviceWorker.register("/firebase-messaging-sw.js");

        // Wait for the service worker to be ready (active)
        const readyRegistration = await navigator.serviceWorker.ready;

        // Now that the service worker is ready, get the FCM token
        try {
          const currentToken = await getToken(messaging, {
            vapidKey: "BK1ZaH0-p-147_4P9NMRk_NIQoJQQCmhAG6-FeULhFGr3_Zu7XJ0_jQpf_ZhUlcpCjefPj6V5hBOByhD0vh5uoI",
            serviceWorkerRegistration: readyRegistration,
          });

          if (currentToken) { 
            // Store the token in localStorage or send it to the server
            localStorage.setItem("fcm_token", currentToken);
          } else {
            console.warn("No registration token available. Request permission to generate one.");
          }
        } catch (err) {
          console.error("An error occurred while retrieving the token:", err);
        }
      } else {
        console.warn("Notification permission denied.");
      }
    } else {
      console.warn("This browser does not support notifications.");
    }
  } catch (err) {
    console.error("An error occurred while requesting notification permission:", err);
  }
}

// Call the function to request notification permission
requestPermission();

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);