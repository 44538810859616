import { createSlice } from "@reduxjs/toolkit";

import { getComapnyDetailsById } from "../services/settings";

interface ConnectionDetailsProps {
  companyId: number,
  name: string,
  email: string | null,
  industryType: Array<string> | null,
  aboutCompany: string | null,
  profileImg: string | null,
  profileThumbnailImg: string | null,
  createdOn: string,
  modifiedOn: string | null,
  isActive: boolean,
  isVerified: boolean,
  createdAt: string,
  updatedAt: string,
  addressLine: string | null,
  orderNumberPrefix: string | null,
  orderNumberSuffix: string | null,
  createdBy: {
      userId: number,
      firstName: string,
      lastName: string,
      email: string | null,
      role: string,
      phoneNo: string,
      isActive: boolean,
      isDelete: boolean,
      isVerified: boolean,
      createdAt: string,
      updatedAt: string
  },
  companyType: {
      businessTypeId: number,
      businessType: string,
      createdOn: string,
      modifiedOn: string,
      isActive: boolean,
      isDelete: boolean
  }[],
  connectionsCount: number,
  city: string,
  state: string,
  receivedOrders: string | number,
  placedOrders: string | number,
  pincode: string,
  district: string,
  country: string,
}

interface companyProfileProps {
  isLoading: boolean,
  isSuccess: boolean,
  message: string,
  data: ConnectionDetailsProps | null
}

interface IProps {
  companyProfile: companyProfileProps
}

const initialState: IProps = {
  companyProfile: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyProfileData: (state, { payload }) => {
      state.companyProfile.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getComapnyDetailsById.pending, (state) => {
      state.companyProfile.data = null;
      state.companyProfile.isLoading = true;
      state.companyProfile.isSuccess = false;
    });
    builder.addCase(getComapnyDetailsById.fulfilled, (state, { payload }: any) => {
      state.companyProfile.isSuccess = true;
      state.companyProfile.isLoading = false;
      state.companyProfile.data = payload?.data;
      state.companyProfile.message = payload?.message;
    });
    builder.addCase(getComapnyDetailsById.rejected, (state, { payload }: any) => {
      state.companyProfile.data = payload;
      state.companyProfile.isSuccess = false;
      state.companyProfile.isLoading = false;
      state.companyProfile.message = payload?.message;
    });
  },
});

export const { setCompanyProfileData } = companySlice.actions;
export default companySlice.reducer;