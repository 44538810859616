import React, { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Spin } from "antd";

import ConnectionDetails from "../pages/connections/connectionDetails";

const AuthLayout = lazy(() => import("../pages/auth/authLayout"));
const Layout = lazy(() => import("../pages/layout"));
const PlaceOrder = lazy(() => import("../pages/placeOrder"));
const Login = lazy(() => import("../pages/auth/login"));
const Products = lazy(() => import("../pages/products"));
const AddProduct = lazy(() => import("../pages/products/AddProduct"));
const Connection = lazy(() => import("../pages/connections"));
const Orders = lazy(() => import("../pages/orders"));
const OrderDetails = lazy(() => import("../pages/orders/orderDetails"));
const SettingsPage = lazy(() => import("../pages/settings"));
const LandingPage = lazy(() => import("../pages/landingPage"));
const ProductDetails = lazy(() => import("../pages/placeOrder/productDetails"));
const Chat = lazy(() => import("../pages/chat"));
const DiscountAndOffers = lazy(() => import("../pages/discountAndOffers"));
const PrivacyPolicy = lazy(() => import("../pages/privacyPolicy"));
const LayoutPage = lazy(() => import("../pages/landingPage/Layout"));
const AboutPage = lazy(() => import("../pages/about"));
const ContactPage = lazy(() => import("../pages/contact"));
const ProfileSetting = lazy(() => import("../pages/settings/profileSetting"));
const SpecialPriceListPage = lazy(() => import("../pages/specialPriceList"));
const ProductsPriceList = lazy(() => import("../pages/specialPriceList/productsPriceList"));
// const ConnectionDetails = lazy(() => import("../pages/connections/connectionDetails"));
const Disclaimer = lazy(() => import("../pages/landingPage/disclaimer"));
const TearmsAndConditions = lazy(() => import("../pages/landingPage/tearmsAndCondition"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const OrdersData = lazy(() => import("../pages/orders/ordersData"));
const Vendors = lazy(() => import("../pages/vendors"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));
const SomethingWrong = lazy(() => import("../pages/ErrorPage/SomethingWrong"));
const Page404 = lazy(() => import("../pages/ErrorPage/404"));
const EditProduct = lazy(() => import("../pages/products/editProduct"));
const GetHelp = lazy(() => import("../pages/getHelp"));

const Routing = () => {
  return (
    <Router>
      <Suspense fallback={<div className="page-loader"><Spin /></div>}>
        <Routes>
          {/* Protected Routes */}
          <Route path="/" element={<Layout />}>
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<EditProduct />} />  {/* WHY ???????????????*/}
            <Route path="/products/add" element={<AddProduct />} />
            <Route path="/placeorder" element={<PlaceOrder />} />
            <Route path="/placeorder/:id" element={<ProductDetails />} />
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/orders" element={<Orders />}>
              <Route path="/orders" element={<OrdersData />} />
              <Route path="/orders/customer-orders" element={<OrdersData />} />
              <Route path="/orders/my-orders" element={<OrdersData />} />
            </Route>
            <Route path="/orders/my-orders/:id" element={<OrderDetails />} />
            <Route path="/orders/customer-orders/:id" element={<OrderDetails />} />
            <Route path="/settings/:id" element={<SettingsPage />} />
            <Route path="/profileSetting/:id" element={<ProfileSetting />} />
            <Route path="/special-price-list" element={<SpecialPriceListPage />}>
              <Route path="/special-price-list/:id" element={<ProductsPriceList />} />
            </Route>
            <Route path="/" element={<Dashboard />}>
              <Route path="*" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/:id" element={<ProductsPriceList />} />
            </Route>

            {/* <Route path="/placeorder/:id" element={<PlaceOrder />} /> */}
            <Route path="/connection" element={<Connection />}>
              <Route path="/connection/:id" element={<ConnectionDetails />} />
            </Route>
            <Route path="/chat" element={<Chat />} />
            {/* <Route path="/vendors" element={<Vendors />} /> */}
            <Route path="/discount&offers" element={<DiscountAndOffers />} />
          </Route>
          {/* Public Routes */}
          <Route path="/500" element={<ErrorPage />} />
          <Route path="/error" element={<SomethingWrong />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/gethelp" element={<GetHelp />} />
          <Route path="/" element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/home" element={<LayoutPage />}>
            <Route path="/home" element={<LandingPage />} />
            <Route path="/home/about" element={<AboutPage />} />
            <Route path="/home/contact" element={<ContactPage />} />
            <Route path="/home/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/home/tearmsandcondition" element={<TearmsAndConditions />} />
            <Route path="/home/disclaimer" element={<Disclaimer />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Routing;