import { createSlice } from "@reduxjs/toolkit";

import { getProfileDetails } from "../services/settings";
import { userProfile } from "../../types/settingTypes";

interface IProps {
  userProfile: {
    isLoading: boolean,
    isSuccess: boolean,
    data: userProfile | null,
    message: string
  }
}

const initialState: IProps = {
  userProfile: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileDetails.pending, (state) => {
      state.userProfile.isLoading = true;
      state.userProfile.isSuccess = false;
    });
    builder.addCase(getProfileDetails.fulfilled, (state, { payload }: any) => {
      state.userProfile.isSuccess = true;
      state.userProfile.isLoading = false;
      state.userProfile.data = payload?.data;
      state.userProfile.message = payload?.message;
    });
    builder.addCase(getProfileDetails.rejected, (state, { payload }: any) => {
      state.userProfile.data = payload;
      state.userProfile.isSuccess = false;
      state.userProfile.isLoading = false;
      state.userProfile.message = payload?.message;
    });
  },
});

// export const { setCompanyProfileData } = settingSlice.actions;
export default settingSlice.reducer;