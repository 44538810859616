import React, { useEffect, useRef, useState } from "react";

import { handleKeyDown } from "../../utils/helper";

interface IProps {
  value?: string,
  placeholder: string,
  name: string,
  label?: string
  required?: boolean;
  type?: string;
  onChange?: any;
  className?: string | null;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  showCount?: boolean;
  handleRemoveField?: () => void;
  suggetion?: string[];
  handleSuggetionClick?: (data: string) => void;
}

export const InputField = ({ handleRemoveField, autoFocus = false, value, placeholder, name, label, required = false, onChange, className, maxLength = 50, type = "text", errorMessage, showCount = false, suggetion, handleSuggetionClick, ...props }: IProps) => {
  const [isFocus, setFocus] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (autoFocus && inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  }, [autoFocus]);
  
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent blur event when clicking inside result-container
  };

  return (
    <div className={className ? className + " input" : "input"}>
      <div className="input-container">
        <label htmlFor={name}>
          {label && (
            <div className="flex justifyBetween">
              <span className="flex gap-1">
                {label}
                {required && <span className="require-icon"> *</span>}
              </span>
              {handleRemoveField ? <button className="remove-field" onClick={() => handleRemoveField()}>Remove</button> : <></>}
            </div>
          )}
          <input
            ref={inputRef}
            value={value}
            type={type}
            name={name}
            placeholder={placeholder}
            {...props}
            onKeyDown={handleKeyDown}
            maxLength={maxLength}
            onChange={onChange}
            autoComplete="off"
            autoFocus={autoFocus}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
        </label>
        <div className="flex alignCenter justifyBetween">
          {errorMessage ? <p className="error-message">{errorMessage}</p> : ""}
          {showCount ? <p className="total-char-count">{value?.length  || inputRef.current?.value.length || 0}/{maxLength}</p> : ""}
        </div>

        {isFocus && suggetion && suggetion?.length && (value?.length || 0) > 0 ?
          <div className="suggesion-container" onMouseDown={handleMouseDown}>
            <ul>
              {suggetion.map((data, index) => <li key={index} onClick={() => handleSuggetionClick && handleSuggetionClick(data)}>{data}</li>)}
            </ul>
          </div> : <></>
        }
      </div>
    </div>
  );
};