import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { DatePicker, message, PaginationProps, Popover, Table, TableProps, Tabs, TimeRangePickerProps } from "antd";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

import {
  getConnectedCompanyOrder,
  getConnectionDetailsById,
  getConnectionList,
} from "../../redux/services/connection";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import NoDataPage from "./noDataPage";
import { Button } from "../../components";
import { StopIcon } from "../../assets/images";
import { DeleteIcon, NoImagesIcon, UnblockIcon, UserIcon, PlusFilledIcon, ChevronDown } from "../../assets/images/icons";
import ThreeDots from "../../assets/images/threeDots";
import { BlockUnblockRemoveConnection } from "../../redux/services/vendor";
import { formatRupees } from "../../utils/helper";
import StatusSwitch from "../orders/statusSwitch";
import { tableSortingIconToggle } from "../../utils/tableSortingIconToggle";
import SearchBar from "../../components/searchBar";
import { getOrderItemsByOrder } from "../../redux/services/orders";
import { debounce } from "../../utils/debounce";
import DispatchOrderModal from "../orders/dispatchOrderModal";
import TransportationDetails from "../orders/transportationDetails";
import useMessageHook from "../../hooks/useMessageHook";
import Pagination from "../../components/pagination";
import { setConnectionOrdersPagination } from "../../redux/slices/paginationSlice";
import socket from "../../socket";
import { clearCompanyOrder } from "../../redux/slices/connectionSlice";

interface dispatchType {
  isDispatch: boolean;
  orderId: number | null;
  isTransport: boolean
}

const ConnectionDetails = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [sort, setSort] = useState({
    order: "ASC",
    field: "createdOn",
  });
  const [date, setDate] = useState<{
    startDate: string,
    endDate: string,
  }>({
    startDate: (dayjs(new Date()).subtract(3, "months").format("DD/MM/YYYY")),
    endDate: (dayjs(new Date()).format("DD/MM/YYYY")),
  });

  const [activeTab, setActiveTab] = useState(1);
  const [searchOrder, setSearchOrder] = useState<string>("");
  const [loading, setLoading] = useState({
    block_unblock: false,
    orders: false,
  });
  const [tableRowCount, setTableRowCount] = useState(10);

  const [toggleDispatch, setToggleDispatch] = useState<dispatchType>({ isDispatch: false, isTransport: false, orderId: null });
  const { showMessage, contextHolder } = useMessageHook();
  const { connectionDetails, connection, companyOrders, connectionActiveTab } = useAppSelector(state => state.connections);
  const { statusDetails } = useAppSelector(state => state.manageOrders);
  const { connectionOrdersPagination } = useAppSelector(state => state.pagination);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const tableHeight = document.querySelector(".orders-content .ant-tabs")?.clientHeight;

  const { orderItems } = useAppSelector(state => state.manageOrders);
  const companiesData = localStorage.getItem("companies");
  const companyId = companiesData ? JSON.parse(companiesData || "[]")?.[0]?.companyId ? JSON.parse(companiesData || "[]")?.[0]?.companyId : JSON.parse(companiesData || "{}")?.companyId : 0;
  const [orderTableHeight, setTableHeight] = useState(0);

  const navigate = useNavigate();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    const updateTableHeight = () => {
      const calculatedHeight = window.innerHeight - 390;
      setTableHeight(calculatedHeight);
    };

    updateTableHeight();

    window.addEventListener("resize", updateTableHeight);
    return () => window.removeEventListener("resize", updateTableHeight);
  }, []);

  useEffect(() => {
    setSearchOrder("");
  }, [connectionDetails]);

  const tableBody = document.querySelector(".ant-table-body");
  
  useEffect(() => {
    if(tableBody){
      tableBody.scrollTop = 0;
    }
  }, [connectionOrdersPagination, tableBody]);

  useEffect(() => {
    setActiveTab(1);
    dispatch(clearCompanyOrder());
    try {
      dispatch(getConnectionDetailsById(Number(params?.id))).then((result) => {
        if (result?.payload?.status === 404) {
          navigate("/connection");
          ListOfAllConnection();
        }
      });
    } catch (error) {
      showMessage("error", "Something Went Wrong");
    }
    if (location?.state?.invite) {
      setVisible(true);
    }
    // if (connectionOrdersPagination.pageSize === 0) { 
    //   const payload = {
    //     pageSize: tableRowCount,
    //     currentPage: connectionOrdersPagination.currentPage,
    //   };
    //   dispatch(setConnectionOrdersPagination(payload));
    // }
  }, [dispatch, params?.id]);

  const ListOfAllConnection = async () => {
    const payload = {
      search: searchOrder,
      sort: sort?.order,
      type: connectionActiveTab,
      limit: 0,
      offset: 0,
    };
    try {
      await dispatch(getConnectionList(payload));
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (connectionDetails?.data?.connection?.companyId) {
      setLoading((prev) => ({
        ...prev,
        orders: true,
      }));
      dispatch(getConnectedCompanyOrder({
        connectedCompanyId: connectionDetails?.data?.connection?.companyId || 0,
        endDate: date.endDate,
        startDate: date.startDate,
        search: searchOrder,
        sort: [sort],
        type: activeTab,
        limit: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount),
        offset: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount) * (connectionOrdersPagination.currentPage - 1),
      })).then(() => {
        setLoading((prev) => ({
          ...prev,
          orders: false,
        }));
      });
    }
  }, [dispatch, connectionDetails?.data?.connection?.companyId, activeTab, date, sort, connectionOrdersPagination]);

  useEffect(() => {
    socket.on(`orderstatusChanged_${companyId}`, (data) => {
      if (data) {
        if (connectionDetails?.data?.connection?.companyId) {
          // setLoading((prev) => ({
          //   ...prev,
          //   orders: true,
          // }));
          dispatch(getConnectedCompanyOrder({
            connectedCompanyId: connectionDetails?.data?.connection?.companyId || 0,
            endDate: date.endDate,
            startDate: date.startDate,
            search: searchOrder,
            sort: [sort],
            type: activeTab,
            // limit: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount),
            // offset: 0,
            limit: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount),
            offset: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount) * (connectionOrdersPagination.currentPage - 1),
          })).then(() => {
            // setLoading((prev) => ({
            //   ...prev,
            //   orders: false,
            // }));
          });
        }
      }
    });

    socket.on(`connectionUpdated_${companyId}`, (data) => {
      if (data.flage) {
        if (data?.connectionId)
          if (+data?.connectionId === +(params?.id || 0))
            navigate("/connection", { replace: true });
          else
            dispatch(getConnectionDetailsById(Number(params?.id)));
      }
    });
  }, [socket]);

  const blockUnblockRemoveConnection = async (value: string) => {
    setLoading((prev) => ({
      ...prev,
      block_unblock: true,
    }));
    await dispatch(BlockUnblockRemoveConnection({
      connectionId: Number(params?.id) || 0,
      action: value,
    })).then((result) => {
      if (result?.payload?.status === 200 && value !== "Remove") {
        showMessage("success", result?.payload?.message);
        dispatch(getConnectionDetailsById(Number(params?.id)));
        setLoading((prev) => ({
          ...prev,
          block_unblock: false,
        }));
      } else {
        if (value === "Remove") {
          const payload = {
            search: searchOrder,
            sort: sort?.order,
            type: connectionActiveTab,
            limit: 0,
            offset: 0,
          };
          try {
            dispatch(getConnectionList(payload)).then((result) => {
              if(result.payload.status === 200){
                navigate("/connection");
              }
            });
          } catch (error: any) {
            message.error(error?.message);
          }
        }
      }
    }).catch(err => {
      setLoading((prev) => ({
        ...prev,
        block_unblock: true,
      }));
      showMessage("error", err?.message);
    });
    setIsPopoverOpen(!isPopoverOpen);
  };

  const orderItemsColumns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 280,
      render: (_, record: any) => (
        <div className="product-name-box flex alignCenter gap-3">
          <div className="product-image-container">
            {record?.product?.productImage?.thumbnailURL ? <img className="product-image" src={record?.product?.productImage?.thumbnailURL} alt="product-img" loading="lazy" /> : <NoImagesIcon />}
          </div>
          <span className="product-name">{record?.product?.productName}</span>
        </div>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 159,
      render: (_, record: any) => (
        <div className="size-text">{record?.productSize?.productSize} {record?.productSize?.productSizeUnit}</div>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 131,
      render: (_, record: any) => (
        <div className="qty-text flex gap-1">
          <span className="quantity">{record?.qty}</span>
          <span className="unit">{record?.quantityOrdered} {record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}</span>
        </div>
      ),
    },
  ];

  const getOrderedProducts = async (id: number) => {
    await dispatch(getOrderItemsByOrder(id));
  };

  const OrderItemContent = () => {
    return (
      <Table
        columns={orderItemsColumns}
        dataSource={orderItems.data || []} // orderItems?.data || 
        // dataSource={[]}
        className="order-items-table"
        pagination={false}
      />
    );
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().add(0, "d"), dayjs()] },
    { label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs()] },
    { label: "This Week", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last Week", value: [dayjs().add(-14, "d"), dayjs().add(-7, "d")] },
    { label: "This Month", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "This Year", value: [dayjs().add(-1, "y"), dayjs()] },
  ];

  const columns = [
    {
      title: "Order No",
      dataIndex: "orderId",
      key: "orderNumber",
      sorter: true,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (text: string, record: any) => {
        return (
          <p className="caption" onClick={() => navigate(`/orders/my-orders/${record?.orderId}`, { state: { activeTab: 1 } })}>
            {record?.orderNumber ? `${record?.orderNumber}` : "-"}
          </p>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_: string, record: any) => {
        return (
          <p className="caption">{dayjs(record?.createdOn).format("DD/MM/YYYY")}</p>
        );
      },
    },
    {
      title: "Ordered Items",
      dataIndex: "orderItem",
      key: "orderItem",
      render: (_: string, record: any) => {
        return (
          <Popover
            content={OrderItemContent}
            trigger="click"
            placement="bottomLeft"
            rootClassName="order-item-popover"
          >
            <button className="ordered-items-btn gap-1 flex alignCenter" onClick={() => getOrderedProducts(record?.orderId)}>
              {record?.orderItemsCount} items <ChevronDown />
            </button>
          </Popover>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (_: string, record: any) => {
        return (
          <StatusSwitch
            status={record?.status === "In Progress" ? "inProgress" : record?.status}
            orderId={record?.orderId}
            onDispatch={() => setToggleDispatch({ orderId: record?.orderId, isDispatch: true, isTransport: false })}
            activeTab={activeTab === 1 ? "Customer-order" : "My-order"}
          // disabled
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_: string, record: any) => {
        return (
          <p className="caption">{formatRupees(record?.grandTotal)}</p>
        );
      },
    },
  ];

  const onRangeChange = (dates: null | (any | null)[], dateStrings: string[]) => {
    if (dates) {
      setDate({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      setDate({
        startDate: (dayjs(new Date()).subtract(3, "months").format("DD/MM/YYYY")),
        endDate: (dayjs(new Date()).format("DD/MM/YYYY")),
      });
    }
  };

  const handlePagination = (data: any) => {
    const payload = {
      pageSize: data?.pageSize,
      currentPage: data?.page,
    };
    dispatch(setConnectionOrdersPagination(payload));
  };

  const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
    if (type === "prev") {
      return <button className="prev-button pagination-button flex alignCenter gap-2"><ArrowLeftOutlined /> Previous</button>;
    }
    if (type === "next") {
      return <button className="next-button pagination-button flex alignCenter gap-2">Next <ArrowRightOutlined /></button>;
    }
    return originalElement;
  };

  const items: any = [
    {
      key: "1",
      label: (
        <span className="tab-label flex gap-2">
          Received Orders
        </span>
      ),
      children: (
        activeTab === 1 ? <div>
          <Table
            columns={columns}
            dataSource={companyOrders?.data?.orders || []}
            pagination={false}
            className="orders-table"
            onChange={(pagination, filter, sorter: any) => {
              setSort({
                order: sorter?.order === "descend" ? "DESC" : "ASC",
                field: sorter?.columnKey || "createdOn",
              });
            }}
            scroll={{ x: "min-content", y: orderTableHeight }}
            loading={loading.orders || orderItems.isLoading}
          />
          {(companyOrders.data?.totalOrders >= 10) ?
            <div className="flex justifyEnd pagination-wrapper">
              <Pagination
                pageSize={connectionOrdersPagination.pageSize}
                pageCount={tableRowCount}
                showSizeChanger={true}
                total={companyOrders.data?.totalOrders || 0}
                current={connectionOrdersPagination.currentPage}
                onChange={handlePagination}
                itemRender={itemRender}
              />
            </div> : <></>}
        </div> : <></>
      ),
    },
    {
      key: "0",
      label: (
        <span className="tab-label flex gap-2">
          Placed Orders
        </span>
      ),
      children: (
        activeTab === 0 ? <div>
          <Table
            columns={columns}
            dataSource={companyOrders.data?.orders || []}
            pagination={false}
            className="orders-table"
            onChange={(pagination, filter, sorter: any) => {
              setSort({
                order: sorter?.order === "descend" ? "DESC" : "ASC",
                field: sorter?.columnKey || "createdOn",
              });
            }}
            scroll={{ x: "min-content", y: orderTableHeight }}
            loading={loading.orders || orderItems.isLoading}
          />
          {(companyOrders.data?.totalOrders >= 10) ?
            <div className="flex justifyEnd pagination-wrapper">
              <Pagination
                pageSize={connectionOrdersPagination.pageSize}
                pageCount={tableRowCount}
                showSizeChanger={true}
                total={companyOrders.data?.totalOrders || 0}
                current={connectionOrdersPagination.currentPage}
                onChange={handlePagination}
                itemRender={itemRender}
              />
            </div> : <></>}
        </div> : <></>
      ),
    },
  ];

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchProducts = useCallback(
    debounce((query: string, tableRowCount: number, pageSize: number, currentPage: number) => {
      if (connectionDetails?.data?.connection?.companyId) {
        setLoading((prev) => ({
          ...prev,
          orders: true,
        }));
        dispatch(getConnectedCompanyOrder({
          connectedCompanyId: connectionDetails?.data?.connection?.companyId || 0,
          endDate: date.endDate,
          startDate: date.startDate,
          search: query,
          sort: [sort],
          limit: (tableRowCount < pageSize || pageSize === 10 ? pageSize : tableRowCount),
          offset: (tableRowCount < pageSize || pageSize === 10 ? pageSize : tableRowCount) * (currentPage - 1),
          // type: (connectionDetails?.data?.placedOrders && connectionDetails?.data?.receivedOrders) ? activeTab : connectionDetails?.data?.placedOrders ? 0 : 1,
          type: activeTab,
        })).then(() => {
          setLoading((prev) => ({
            ...prev,
            orders: false,
          }));
        });
      }
    }, 500),
    [dispatch, searchOrder, connectionDetails?.data?.connection?.companyId],
  );

  useEffect(() => {
    debouncedFetchProducts(searchOrder, tableRowCount, connectionOrdersPagination.pageSize, connectionOrdersPagination.currentPage);
  }, [searchOrder, debouncedFetchProducts, statusDetails.isSuccess]);

  useEffect(() => {
    setTableRowCount(Math.floor((tableHeight || 0) / 57));
  }, [connectionOrdersPagination.pageSize, tableHeight, activeTab]);

  const handleSearchOrder = (query: string) => {
    setSearchOrder(query);
  };

  const handleActiveTab = (key: string) => {
    setActiveTab(Number(key));
    const payload = {
      pageSize: tableRowCount,
      currentPage: 1,
    };
    dispatch(setConnectionOrdersPagination(payload));
  };

  return (
    <>
      {contextHolder}
      {/* {connectionDetails?.isLoading || orderItems.isLoading ? <div className="page-loader"><Spin /></div> : ""} */}
      <div className="connection-details w-100">
        <div className={(connection?.data.length > 0) ? "card" : "no-data-screen h-100"}>
          {(connection?.data?.length > 0) ?
            <div className="details-card gap-8">
              <div className="profile-image-container">
                <div className="profile-image flex justifyCenter alignCenter">
                  {connectionDetails?.isLoading ? <Skeleton circle width={180} height={180} /> : connectionDetails?.data?.connection?.profileImg ? <img src={connectionDetails?.data?.connection?.profileImg} alt="avatar" loading="lazy" /> : <UserIcon />}
                </div>
              </div>
              <div className="profile-description">
                <div className="product-summary-section">
                  <div className="summary">
                    <h3 className="title-3">{connectionDetails?.isLoading ? <Skeleton width={200} /> : connectionDetails?.data?.connection?.name}</h3>
                    <p className="caption">{connectionDetails?.isLoading ? <Skeleton width={120} /> : connectionDetails?.data?.connection?.aboutCompany}</p>
                  </div>
                  {/* <button className="notification-button" type="button">
                    <BellOutlined />
                  </button> */}
                </div>
                <div className="address-connection-section">
                  <p className="address-text caption">
                    {connectionDetails?.isLoading ? <Skeleton width={180} /> : <>{connectionDetails?.data?.connection?.city || ""}, {connectionDetails?.data?.connection?.state || ""}, India.</>}
                  </p>
                  <button className="connections-count">
                    {connectionDetails?.isLoading ? <Skeleton width={100} /> : <>{connectionDetails?.data?.connection?.connectionsCount} Connections</>}
                  </button>
                </div>
                <div className="orders-message-container">
                  <div className="orders-details">
                    {/* {(connectionInfo?.isVendor || connectionDetails?.data?.receivedOrders) ?  */}
                    <div className="received-order-details">
                      <p className="caption">Received Order</p>
                      <h4 className="title-4">{connectionDetails?.isLoading ? <Skeleton width={60} /> : (connectionDetails?.data?.connection?.receivedOrders || "0")}</h4>
                    </div>
                    {/* : <></>} */}
                    {/* {(connectionInfo?.isCustomer || connectionDetails?.data?.placedOrders) ?  */}
                    <div className="placed-order-details">
                      <p className="caption">Placed Order</p>
                      <h4 className="title-4">{connectionDetails?.isLoading ? <Skeleton width={60} /> : (connectionDetails?.data?.connection?.placedOrders || "0")}</h4>
                    </div>
                    {/* : <></>} */}
                  </div>
                  <div className="messages-container gap-3">
                    {connectionDetails?.isLoading ? <Skeleton width={139} height={36}/> : <>
                      {(connectionDetails?.data?.connection?.isVendor && !connectionDetails?.data?.connection?.isBlock) &&
                        <Button onClick={() => navigate("/placeorder", { state: { orderId: connectionDetails?.data?.connection?.companyId, companyName: connectionDetails?.data?.connection?.name, companyProfile: connectionDetails?.data?.connection?.profileImg, username: ((connectionDetails.data?.connection?.createdBy?.firstName || "") + (connectionDetails.data?.connection?.createdBy?.lastName || "")) } })} className="placeorder-button primary large" type="button">
                          <PlusFilledIcon /> Place Order
                        </Button>}
                    </>}
                    {/* <button className="message-button" type="button"><MessageOutlined /> Message</button> */}
                    {/* <button type="button" className="clock-button"><ClockCircleOutlined /></button> */}
                    <Popover
                      content={() => (
                        <div>
                          {
                            <button className="block" disabled={loading?.block_unblock || false} onClick={() => blockUnblockRemoveConnection(connectionDetails?.data?.connection?.isBlock ? "Unblock" : "Block")}>
                              {connectionDetails?.data?.connection?.blockByCompany === companyId ?
                                <><UnblockIcon /> Unblock</> :
                                <><StopIcon />Block</>
                              }
                            </button>
                          }
                          <button disabled={loading.block_unblock} className="remove" onClick={() => blockUnblockRemoveConnection("Remove")}><DeleteIcon />Remove</button>
                        </div>
                      )}
                      open={isPopoverOpen}
                      onOpenChange={() => setIsPopoverOpen(!isPopoverOpen)}
                      trigger="click"
                      rootClassName="block-or-remove-container"
                      placement="bottomRight"
                    >
                      {connectionDetails?.isLoading ? <Skeleton width={36} height={36}/> : <button type="button" className="square-button"><ThreeDots /></button>}
                    </Popover>
                  </div>
                </div>
              </div>
            </div> :
            <NoDataPage onClick={showModal} />}
        </div>
        {/* {(((connectionDetails?.data?.receivedOrders) || (connectionDetails?.data?.placedOrders)) && (companyOrders?.data?.orders?.length || searchOrder?.length || !companyOrders?.isLoading)) ? */}

        {connection.data.length ?
          <div className="card orders-content">
            <Tabs
              defaultActiveKey="1"
              items={items}
              activeKey={activeTab?.toString()}
              tabBarExtraContent={
                <div className="tabs-right-order-actions flex gap-4">
                  <SearchBar
                    onChange={(e) => handleSearchOrder(e.target.value)}
                    value={searchOrder}
                    placeholder="Search Order"
                  />
                  <div className="date-picker">
                    <RangePicker
                      presets={rangePresets}
                      onChange={onRangeChange}
                      height={36}
                      width={400}
                      placement="bottomRight"
                      format="DD/MM/YYYY"
                      defaultValue={[dayjs(new Date()).subtract(3, "months"), dayjs(new Date())]}
                      allowClear={false}
                      maxDate={dayjs(new Date())}
                    />
                  </div>
                </div>
              }
              onChange={(key) => { handleActiveTab(key); }}
            />
          </div> : <></>}
      </div>
      {toggleDispatch?.isDispatch &&
        <DispatchOrderModal
          open={toggleDispatch?.isDispatch}
          close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
          orderId={toggleDispatch.orderId || 0}
          handleConfirm={() => setToggleDispatch({ isDispatch: false, isTransport: true, orderId: toggleDispatch.orderId })}
        />
      }
      {toggleDispatch?.isTransport &&
        <TransportationDetails
          open={toggleDispatch.isTransport}
          close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
          orderId={toggleDispatch.orderId || 0}
        />}
    </>
  );
};

export default ConnectionDetails;