import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

import { getImage } from "./s3config";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

export const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement | HTMLTextAreaElement>) => {
  const { key, target } = e;
  const { value, selectionStart } = target as HTMLInputElement;

  // Ensure selectionStart is not null
  if (selectionStart === null) return;

  // Prevent space at the start
  if (key === " " && selectionStart === 0) {
    e.preventDefault();
    return;
  }

  // Prevent multiple consecutive spaces
  if (key === " " && value[selectionStart - 1] === " ") {
    e.preventDefault();
    return;
  }

  // Prevent Enter if the last character is a newline
  if (key === "Enter" && value[value.length - 1] === "\n") {
    e.preventDefault();
  }
};

export const cleanValue = (val: string): string => val.replace(/[^0-9.]/g, "");

export const truncateString = (str: string, maxLength: number): string => {
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
};

export const convertToNumber = (value: string | number): number => {
  return Number(value);
};

export const getProfileIcon = (name: string): string => {
  const words = name?.split(" ");
  if (words?.length === 1) {
    return words[0].charAt(0).toUpperCase();
  } else if (words?.length > 1) {
    return `${words[0].charAt(0).toUpperCase()}${words[1].charAt(0).toUpperCase()}`;
  }
  return "";
};

export const formatRupees = (number: number, amount = true) => {
  // return number ? ((amount ? "₹" : "") + number?.toLocaleString("en-IN", {
  //   maximumSignificantDigits: 15,
  // })) : "₹0";

  return (
    !amount ? number?.toLocaleString("en-IN", {
      maximumSignificantDigits: 15,
    }) : new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number)
  );
  // return new Intl.NumberFormat("en-IN", {
  //   style: "currency",
  //   currency: "INR",
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // }).format(number);
};

export const formatPercentage = (amount: any) => {
  return new Intl.NumberFormat("en-IN", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount / 100);
};

export const AddressFormat = (value: any) => {
  let address = "";
  if (!value?.addressLine) {
    address = `${value?.district}, ${value?.state}, ${value?.country}`;
  } else {
    address = `${value?.addressLine}, ${value?.district}, ${value?.state}, ${value?.pincode}`;
  }
  return address;
};

export function formatDate(inputDate: string): string {

  const date = dayjs(inputDate);

  // Format the date using dayjs
  const formattedDate = date.format("D MMMM, YYYY h:mmA");

  return formattedDate;
  // const date = new Date(inputDate);

  // // Define options for formatting date and time
  // const options: Intl.DateTimeFormatOptions = {
  //   day: "numeric",
  //   month: "long",
  //   year: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  // };

  // // Format the date using the options
  // const formattedDate = date.toLocaleString("en-GB", options);

  // // Adjust the format to match the required output
  // return formattedDate.replace(/(\d{1,2}) (\w+), (\d{4}), (\d{1,2}:\d{2})(am|pm)/, "$1 $2, $3 $4$5");
}

export const getRelativeTime = (timestamp: string | number | Date): string => {
  return dayjs(timestamp).fromNow();
};

export const getDateLabel = (date: Date) => {
  const today = dayjs().startOf("day");
  const yesterday = dayjs().subtract(1, "day").startOf("day");
  const notificationDate = dayjs(date).startOf("day");

  if (notificationDate.isSame(today)) {
    return "Today";
  } else if (notificationDate.isSame(yesterday)) {
    return "Yesterday";
  } else {
    return dayjs(date).format("DD MMMM YYYY");
  }
};

export const groupDataByDate = (data: any[]) => {
  const grouped: Record<string, any[]> = data.reduce((acc, item) => {
    const date = dayjs(item.createdOn).format("YYYY-MM-DD"); // Format to 'YYYY-MM-DD'
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {} as Record<string, any[]>);

  return Object.fromEntries(
    Object.entries(grouped).sort(([dateA], [dateB]) => dayjs(dateB).diff(dayjs(dateA))), // Sort by date in descending order
  );
  // const grouped: Record<string, any[]> = data.reduce((acc, item) => {
  //   const date = new Date(item.createdOn).toISOString().split("T")[0];
  //   if (!acc[date]) {
  //     acc[date] = [];
  //   }
  //   acc[date].push(item);
  //   return acc;
  // }, {} as Record<string, any[]>);

  // return Object.fromEntries(
  //   Object.entries(grouped).sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime()),
  // );
};

export const formatDateTime = (dateString: string): string => {

  const date = dayjs(dateString);

  // Format date (Day, Month, Year)
  const formattedDate = date.format("DD MMMM, YYYY");

  // Format time (Hour:Minute AM/PM)
  const formattedTime = date.format("hh:mm A").toLowerCase();

  // Combine date and time
  return `${formattedDate} ${formattedTime}`;

  // const date = new Date(dateString);

  // // Options for date formatting
  // const dateOptions: Intl.DateTimeFormatOptions = {
  //   day: "2-digit",
  //   month: "long",
  //   year: "numeric",
  // };

  // // Options for time formatting
  // const timeOptions: Intl.DateTimeFormatOptions = {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: true,
  // };

  // // Format date and time
  // const formattedDate = new Intl.DateTimeFormat("en-GB", dateOptions).format(date);
  // const formattedTime = new Intl.DateTimeFormat("en-GB", timeOptions).format(date);

  // // Combine date and time
  // return `${formattedDate} ${formattedTime.toLowerCase()}`;
};

// convert Date into time

export function ConvertDateToTime(dateInput: Date | string): string {

  const date = dayjs(dateInput);

  // Format the time in 12-hour format with AM/PM
  return date.format("hh:mm A");

  // // Create a Date object from the input
  // const date = new Date(dateInput);

  // // Extract hours and minutes from the Date object
  // let hours = date.getHours();
  // const minutes = date.getMinutes();

  // // Determine AM/PM suffix
  // const ampm = hours >= 12 ? "PM" : "AM";

  // // Convert hours from 24-hour to 12-hour format
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'

  // // Format hours and minutes with leading zeroes if needed
  // const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  // const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // // Return the formatted time string
  // return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export function calculateDiscount(
  mainRupees: number,
  value: number,
  isRateInRupees = false,
): string {
  // If the value is percentage, calculate the discounted price
  if (isRateInRupees) {
    // If the value is discounted rupees, calculate the percentage discount
    const discountPercentage = ((mainRupees - value) / mainRupees) * 100;
    return `In Percentage: ${mainRupees != value ? (discountPercentage < 0 ? -discountPercentage.toFixed(2) + "% extra" : discountPercentage.toFixed(2) + "% less") : discountPercentage === 0 ? "0% discount" : "100% discount"}`; // Return discount percentage
  } else {
    const discountedPrice = mainRupees - (mainRupees * (value / 100));
    return `In Rupees: ${formatRupees(Number(discountedPrice.toFixed(2)))}`; // Return discounted price
  }
}

export function calculatePrice(oldPrice: number, newPrice?: number) {
  const price = oldPrice ?? 0; // Original price
  const percentage = newPrice ?? 0; // Percentage to subtract

  // Calculate the amount to subtract
  const amountToSubtract = (price * percentage) / 100;
  return formatRupees(newPrice ? price - amountToSubtract : oldPrice);
}

async function urlToFile(url: string, filename: string, mimeType: string): Promise<any> {

  // try {
  //   // Fetch the image
  //   const response = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "image/jpeg", // Adjust the content type as needed
  //     },
  //   });

  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }

  //   // Convert the image to a blob
  //   const blob = await response.blob();

  //   const file = new File([blob], filename, { type: blob.type });
  //   return file;
  // } catch (error) {
  //   return null;
  // }
  if (url) {
    const key = url?.split("/")?.splice(3)?.join("/");

    const imageBlob = await getImage("bizline-staging-bucket", key);
    if (imageBlob) {
      const file = new File([imageBlob], filename, { type: mimeType });
      return file;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

async function convertUrlsToFiles(urls: string[]): Promise<File[]> {

  const files: File[] = [];

  for (const url of urls) {

    const filename = url.split("/").pop() || "image.jpg"; // Extract filename or use default

    const mimeType = `image/${filename.split(".").pop()?.toLowerCase()}` || "image/jpeg"; // Determine MIME type

    const file = await urlToFile(url, filename, mimeType);
    files.push(file);
  }

  return files;
}

export const handleImages = async (fileList: any) => {
  const fileteredImages: string[] = fileList?.length ? fileList?.filter((data: any) => typeof data.url === "string").map((data: { url: any; }) => data.url) : [];
  const data = await convertUrlsToFiles(fileteredImages);

  const NewFiledata: any = fileList?.length ? fileList?.filter((data: any) => data.originFileObj).map((data: any) => data.originFileObj) : [];

  const finalData = [...data, ...NewFiledata];

  return finalData;
};

export const FormatePrice = (value: number, round = 0, currency = true) => {
  const val = value;
  let newVal = "";
  if (val / 10000000 >= 1) {
    newVal = (val / 10000000)?.toFixed(round) + "Cr";
  } else if (val / 100000 >= 1) {
    newVal = (val / 100000)?.toFixed(round) + "L";
  } else if (val / 1000 >= 1 && val / 100000 < 10) {
    newVal = (val / 1000)?.toFixed(round) + "K";
  } else if (val < 0) {
    if ((0 - val) / 10000000 >= 1) {
      newVal = "-" + ((0 - val) / 10000000)?.toFixed(round) + "Cr";
    } else if ((0 - val) / 100000 >= 1) {
      newVal = "-" + ((0 - val) / 100000)?.toFixed(round) + "L";
    } else if ((0 - val) / 1000 >= 1 && (0 - val) / 100000 < 10) {
      newVal = "-" + ((0 - val) / 1000)?.toFixed(round) + "K";
    }
  } else {
    newVal = val?.toString();
  }
  if (!newVal) {
    return newVal;
  }
  if (currency) {
    newVal = "₹" + newVal;
  }
  return newVal;
};
export function convertChatMessage(date: string) {

  const ChatDate = dayjs(date);

  // Format the date to "Thursday 10:16am"
  const formattedDate = ChatDate.format("hh:mmA");
  return formattedDate;
}

export const getSectionLabel = (dateStr: string) => {
  const date = dayjs(dateStr);
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");

  if (date.isSame(today, "day")) {
    return "Today";
  } else if (date.isSame(yesterday, "day")) {
    return "Yesterday";
  } else {

    return date.format("MMMM D, YYYY");
  }
};