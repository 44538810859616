import { createSlice } from "@reduxjs/toolkit";

import { acceptOrRejectOrder, getCompanyOrderData, getDispatchDetails, getNewOrders, getOrderDetails, getOrderItemsByOrder, getOrdersData, orderDispatch, updateStatusByOrderId } from "../services/orders";
import { OrderDetailsProps, orderItemsType } from "../../types/ordersTypes";

interface IProps {
  newOrders: {
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    data: NewOrderProps | null,
  },
  companyOrderData: {
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    data: OrderDetailsProps[] | null,
  },
  orderData: {
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    data: {
      orders: OrderDetailsProps[],
      totalOrders: number,
      customerOrdersCount: number,
      vendorOrdersCount: number,
    } | null,
  },
  orderItems: {
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    data: orderItemsType[] | null,
  },
  orderDetails: {
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    data: OrderDetailsProps | null,
  },
  statusDetails: {
    data: any,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
  }
  acceptOrRejectOrderData: {
    data: any,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
  },
  getDispatchDetailsData: {
    data: any,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
  }
}

interface NewOrderProps {
  customers: {
    customerId: number,
    customerName: string,
    orderCount: number,
    unseenOrderCount: number,
    isNew: boolean,
    customerProfileThumbnailImg: string,
  }[]
}

const initialState: IProps = {
  newOrders: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  companyOrderData: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  orderData: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  orderItems: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  orderDetails: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  statusDetails: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  acceptOrRejectOrderData: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  getDispatchDetailsData: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
};

const ordersSlice = createSlice({
  name: "manageOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewOrders.pending, (state) => {
      state.newOrders.isLoading = true;
      state.newOrders.isSuccess = false;
    });
    builder.addCase(getNewOrders.fulfilled, (state, { payload }: any) => {
      state.newOrders.isLoading = false;
      state.newOrders.isSuccess = true;
      state.newOrders.data = payload?.data;
      state.newOrders.message = payload?.message;
    });
    builder.addCase(getNewOrders.rejected, (state, { payload }: any) => {
      state.newOrders.isLoading = false;
      state.newOrders.isSuccess = false;
      state.newOrders.data = payload?.data;
      state.newOrders.message = payload?.message;
    });
    builder.addCase(getCompanyOrderData.pending, (state) => {
      state.companyOrderData.isLoading = true;
      state.companyOrderData.isSuccess = false;
    });
    builder.addCase(getCompanyOrderData.fulfilled, (state, { payload }: any) => {
      state.companyOrderData.isLoading = false;
      state.companyOrderData.isSuccess = true;
      state.companyOrderData.data = payload?.data;
      state.companyOrderData.message = payload?.message;
    });
    builder.addCase(getCompanyOrderData.rejected, (state, { payload }: any) => {
      state.companyOrderData.isLoading = false;
      state.companyOrderData.isSuccess = false;
      state.companyOrderData.data = payload?.data;
      state.companyOrderData.message = payload?.message;
    });
    builder.addCase(getOrdersData.pending, (state) => {
      state.orderData.isLoading = true;
      state.orderData.isSuccess = false;
    });
    builder.addCase(getOrdersData.fulfilled, (state, { payload }: any) => {
      state.orderData.isLoading = false;
      state.orderData.isSuccess = true;
      state.orderData.data = payload?.data;
      state.orderData.message = payload?.message;
    });
    builder.addCase(getOrdersData.rejected, (state, { payload }: any) => {
      state.orderData.isLoading = false;
      state.orderData.isSuccess = false;
      state.orderData.data = payload?.data;
      state.orderData.message = payload?.message;
    });

    builder.addCase(getOrderItemsByOrder.pending, (state) => {
      state.orderItems.data = initialState.orderItems.data;
      state.orderItems.isLoading = true;
      state.orderItems.isSuccess = false;
    });
    builder.addCase(getOrderItemsByOrder.fulfilled, (state, { payload }: any) => {
      state.orderItems.isLoading = false;
      state.orderItems.isSuccess = true;
      state.orderItems.data = payload?.data;
      state.orderItems.message = payload?.message;
    });
    builder.addCase(getOrderItemsByOrder.rejected, (state, { payload }: any) => {
      state.orderItems.isLoading = false;
      state.orderItems.isSuccess = false;
      state.orderItems.data = payload?.data;
      state.orderItems.message = payload?.message;
    });

    builder.addCase(getOrderDetails.pending, (state) => {
      state.orderDetails.isLoading = true;
      state.orderDetails.isSuccess = false;
      state.orderDetails.data = initialState.orderDetails.data;
    });
    builder.addCase(getOrderDetails.fulfilled, (state, { payload }: any) => {
      state.orderDetails.isLoading = false;
      state.orderDetails.isSuccess = true;
      state.orderDetails.data = payload?.data;
      state.orderDetails.message = payload?.message;
    });
    builder.addCase(getOrderDetails.rejected, (state, { payload }: any) => {
      state.orderDetails.isLoading = false;
      state.orderDetails.isSuccess = false;
      state.orderDetails.data = payload?.data;
      state.orderDetails.message = payload?.message;
    });

    builder.addCase(updateStatusByOrderId.pending, (state) => {
      state.statusDetails.isLoading = true;
      state.statusDetails.isSuccess = false;
    });
    builder.addCase(updateStatusByOrderId.fulfilled, (state, { payload }: any) => {
      state.statusDetails.isLoading = false;
      state.statusDetails.isSuccess = true;
      state.statusDetails.data = payload?.data;
      state.statusDetails.message = payload?.message;
    });
    builder.addCase(updateStatusByOrderId.rejected, (state, { payload }: any) => {
      state.statusDetails.isLoading = false;
      state.statusDetails.isSuccess = false;
      state.statusDetails.data = payload?.data;
      state.statusDetails.message = payload?.message;
    });

    builder.addCase(orderDispatch.pending, (state) => {
      state.statusDetails.isLoading = true;
      state.statusDetails.isSuccess = false;
    });
    builder.addCase(orderDispatch.fulfilled, (state, { payload }: any) => {
      state.statusDetails.isLoading = false;
      state.statusDetails.isSuccess = true;
      state.statusDetails.message = payload?.message;
    });
    builder.addCase(orderDispatch.rejected, (state, { payload }: any) => {
      state.statusDetails.isLoading = false;
      state.statusDetails.isSuccess = false;
      state.statusDetails.message = payload?.message;
    });

    // acceptOrRejectOrder

    builder.addCase(acceptOrRejectOrder.pending, (state) => {
      state.acceptOrRejectOrderData.isLoading = true;
      state.acceptOrRejectOrderData.isSuccess = false;
    });
    builder.addCase(acceptOrRejectOrder.fulfilled, (state, { payload }: any) => {
      state.acceptOrRejectOrderData.isLoading = false;
      state.acceptOrRejectOrderData.isSuccess = true;
      state.acceptOrRejectOrderData.message = payload?.message;
    });
    builder.addCase(acceptOrRejectOrder.rejected, (state, { payload }: any) => {
      state.acceptOrRejectOrderData.isLoading = false;
      state.acceptOrRejectOrderData.isSuccess = false;
      state.acceptOrRejectOrderData.message = payload?.message;
    });
    
    // getDispatchDetails

    builder.addCase(getDispatchDetails.pending, (state) => {
      state.getDispatchDetailsData.isLoading = true;
      state.getDispatchDetailsData.isSuccess = false;
    });
    builder.addCase(getDispatchDetails.fulfilled, (state, { payload }: any) => {
      state.getDispatchDetailsData.isLoading = false;
      state.getDispatchDetailsData.isSuccess = true;
      state.getDispatchDetailsData.data = payload?.data;
    });
    builder.addCase(getDispatchDetails.rejected, (state, { payload }: any) => {
      state.getDispatchDetailsData.isLoading = false;
      state.getDispatchDetailsData.isSuccess = false;
      state.getDispatchDetailsData.message = payload?.message;
    });
  },
});

export default ordersSlice.reducer;